@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap");
/* -----------------
  General variables
---------------------*/
/* -----------------
  General
---------------------*/
body {
  background-color: #303030;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  color: #303030;
}

.center-div {
  margin-right: auto;
  margin-left: auto;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-width {
  width: 300px;
}

.section-title {
  margin: 0;
  color: #f2f2f2;
  font-size: 1.7rem;
}

.section-title-big {
  margin: 0;
  color: #f2f2f2;
  font-size: 3rem;
}

.background-text {
  font-size: 1rem;
}

.title-right {
  margin-left: auto;
  text-align: end;
  padding-right: 1rem;
}

.title-left {
  margin-right: auto;
  text-align: left;
  padding-left: 1rem;
}

.call-to-action {
  height: 3rem;
  width: 10rem;
  background-color: #eeba29;
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
}

.option-button {
  height: 3rem;
  width: 10rem;
  background-color: #C0C0C0;
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
}

.call-to-action:hover {
  background-color: #bd8723;
  cursor: pointer;
}

.option-button:hover {
  background-color: #A0A0A0;
  cursor: pointer;
}

.card-shadow {
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1);
}

.card-title-shadow {
  -webkit-text-shadow: 0px 6px 13px rgba(0, 0, 0, 0.1);
  -moz-text-shadow: 0px 6px 13px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 6px 13px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 901px) {
  .mobile-heading {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .desktop-heading {
    display: none;
  }
}

/* -----------------
  Header variables
---------------------*/
/* -----------------
   Header
 ---------------------*/
.header-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-self: stretch;
  height: 5rem;
  width: 90%;
  min-width: 250px;
  margin-top: 1rem;
  z-index: 1;
}
@media screen and (max-width: 900px) {
  .header-wrapper {
    position: fixed;
    background-color: #f2f2f2;
    width: 100%;
    margin-top: 0;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 350px) {
  .header-wrapper {
    justify-content: flex-end;
  }
}

.header-icon {
  height: 5rem;
  z-index: 100;
}
@media screen and (max-width: 450px) {
  .header-icon {
    display: none;
  }
}

.header-title {
  height: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 2rem;
}
@media screen and (max-width: 450px) {
  .header-title {
    margin-right: 0.5rem;
  }
}
@media screen and (max-width: 350px) {
  .header-title {
    margin-left: 1rem;
    height: 1.25rem;
  }
}

.header-box {
  background-color: #f2f2f2;
  height: 1rem;
  width: 95%;
  margin-left: -1rem;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
@media screen and (max-width: 900px) {
  .header-box {
    box-shadow: none;
  }
}

.main-menu-item {
  text-decoration: none;
  margin-left: 1rem;
  color:#303030
}

@media screen and (max-width: 900px) {
  .main-menu {
    display: none;
  }
}

.main-menu-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  margin-top: 3.5rem;
  margin-right: -1rem;
  padding: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 2rem;
  width: 40%;
  background-color: #ededed;
}

.mobile-menu-wrapper {
  display: none;
}
@media screen and (max-width: 900px) {
  .mobile-menu-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

.mobile-menu-item {
  text-decoration: none;
  font-size: 1.75rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mobile-menu-seperator {
  width: 100%;
}

/* -----------------
  Cookie Consent
---------------------*/
.cookie-banner {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 2rem;
  padding-top: 0rem;
  padding-left: 4rem;
  background-color: #f2f2f2;
  font-size: 1rem;
  z-index: 1;
}
@media screen and (min-width: 901px) {
  .cookie-banner {
    width: 30%;
  }
}

.second-button {
  margin-left: 1rem;
}

/* -----------------
  Background Image
---------------------*/
.back-image {
  width: 100%;
  min-width: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 900px) {
  .first-background {
    margin-top: 5rem;
  }
}
@media screen and (min-width: 900px) {
  .back-image {
    min-width: 1400px;
  }
}
/* -----------------
  News-Card
---------------------*/
.news-card-wrapper {
  width: 70%;
}
@media screen and (max-width: 900px) {
  .news-card-wrapper {
    width: 100%;
    margin-top: -3rem;
  }
}

.news-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  padding: 1rem;
  padding-right: 2rem;
  background-color: #f2f2f2;
}
@media screen and (max-width: 600px) {
  .news-card {
    flex-direction: column;
    align-items: center;
    padding-right: 1rem;
  }
}

.news-image {
  width: 20rem;
}
@media screen and (max-width: 600px) {
  .news-image {
    width: 100%;
  }
}

.news-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: auto;
  justify-self: stretch;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.news-text {
  max-height: 3.5rem;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .news-text {
    max-height: none;
    overflow: auto;
  }
}

/* -----------------
  Address
---------------------*/
.address-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 4rem;
  width: 100%;
  height: 20rem;
  background-color: #ededed;
}
@media screen and (max-width: 900px) {
  .address-wrapper {
    flex-wrap: wrap;
    padding-top: 2rem;
    height: 34rem;
  }
}

@media screen and (max-width: 900px) {
  .column-middle {
    order: 1;
  }
}

.address-column {
  text-align: center;
}
@media screen and (max-width: 900px) {
  .address-column {
    margin-right: 3rem;
    margin-left: 3rem;
  }
}

/* -----------------
  Leasing Card
---------------------*/
.leasing-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 3rem;
}
@media screen and (max-width: 900px) {
  .leasing-card-wrapper {
    width: 100%;
    margin-left: 0;
    margin-top: -5rem;
  }
}

.leasing-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  margin-right: auto;
  padding: 1rem;
  padding-right: 2rem;
  width: 30rem;
  background-color: #f2f2f2;
}
@media screen and (max-width: 900px) {
  .leasing-card {
    flex-direction: column;
    width: 100%;
    padding-right: 1rem;
    box-sizing: border-box;
  }
}

.leasing-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: auto;
  margin-left: 2rem;
  padding-bottom: 1rem;
  width: 50%;
}
.leasing-text {
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .leasing-text {
    max-height: none;
    overflow: auto;
  }
}

.leasing-list {
  width: 50%;
}

/* -----------------
  Service Finanzierung
---------------------*/
.service-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 2rem;
}
@media screen and (max-width: 900px) {
  .service-title {
    display: none;
  }
}

.service-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 1rem;
  width: 100%;
  height: 22rem;
  background-color: #ededed;
}
@media screen and (max-width: 900px) {
  .service-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2rem;
    height: 31rem;
  }
}
@media screen and (max-width: 600px) {
  .service-wrapper {
    height: 40rem;
  }
}

.service-column {
  width: 33rem;
  margin-left: 2rem;
}
@media screen and (max-width: 900px) {
  .service-column {
    margin-right: 2rem;
    width: unset;
  }
}

/* -----------------
  Ebike Card
---------------------*/
.ebike-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 3rem;
}
@media screen and (max-width: 900px) {
  .ebike-card-wrapper {
    width: 100%;
    margin-right: 0;
    margin-top: -5rem;
  }
}

.ebike-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  margin-left: auto;
  margin-right: 3rem;
  padding: 1rem;
  width: 40rem;
  background-color: #f2f2f2;
}
@media screen and (max-width: 900px) {
  .ebike-card {
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    box-sizing: border-box;
  }
}

/* -----------------
  Service Werkstatt
---------------------*/
.service-wrapper-werkstatt {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 1rem;
  width: 100%;
  height: 22rem;
  background-color: #ededed;
}
@media screen and (max-width: 900px) {
  .service-wrapper-werkstatt {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2rem;
    height: 45rem;
  }
}

/* -----------------
  Products
---------------------*/
.product-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0rem;
  width: 100%;
  height: 50rem;
  background-color: #ededed;
}
@media screen and (max-width: 900px) {
  .product-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2rem;
    height: 100rem;
  }
}
@media screen and (max-width: 450px) {
  .product-wrapper {
    height: 70rem;
  }
}

.product-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.product-column {
  width: 15rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
@media screen and (((min-width: 901px) and (max-width: 1250px)) or (max-width: 640px)) {
  .product-column {
    width: 11rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .product-column {
    width: 7rem;
  }
}

.product-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 4rem;
  height: 8rem;
}
@media screen and (max-width: 450px) {
  .product-row {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.product-logo {
  max-width: 100%;
  max-height: 100%;
}

/* -----------------
  Contact Form
---------------------*/
.contact-card-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media screen and (max-width: 900px) {
  .contact-card-wrapper {
    width: 100%;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .contact-card-wrapper {
    flex-direction: row;
    align-items: center;
    background-color: #f2f2f2;
  }
}
@media screen and (max-width: 600px) {
  .contact-card-wrapper {
    margin-top: -5rem;
  }
}

.contact-error {
  font-size: 0.75rem;
  color: #861e1e;
  margin-left: 1rem;
}

.contact-title {
  margin: 0;
  color: #f2f2f2;
  font-size: 3rem;
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .contact-title {
    color: #303030;
  }
}

.contact-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-right: auto;
  padding: 1rem 3rem 1rem 3rem;
  width: 22rem;
  background-color: #f2f2f2;
}
@media screen and (max-width: 900px) {
  .contact-card {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: none;
  }
}

.contact-input {
  margin-top: 0rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
}

.contact-message {
  height: 8rem;
  resize: none;
}

.submit-area {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-submit {
  margin-top: 0.5rem;
  height: 2.5rem;
}

.contact-success {
  margin-left: 1rem;
  font-size: 1rem;
}

/* -----------------
  Contact Maps
---------------------*/
.contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 40rem;
  background-color: #ededed;
}
@media screen and (max-width: 900px) {
  .contact-wrapper {
    height: 70rem;
  }
}
@media screen and (max-width: 500px) {
  .contact-wrapper {
    height: 50rem;
  }
}

.anfahrt-title {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-bottom: 0rem;
}

.maps-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .maps-wrapper {
    flex-wrap: wrap;
  }
}

.maps-iframe {
  display: block;
  width: 45%;
  height: 30rem;
  margin-top: 1rem;
}
@media screen and (max-width: 900px) {
  .maps-iframe {
    width: 95%;
  }
}
@media screen and (max-width: 500px) {
  .maps-iframe {
    height: 20rem;
  }
}

/* -----------------
  Footer
---------------------*/
.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2rem;
  font-size: 0.7rem;
  color: #f2f2f2;
  background-color: #303030;
}

.footer-logo-title {
  width: 15rem;
}

.footer-logo-icon {
  width: 2rem;
}

.footer-nav {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.footer-nav-item {
  margin-top: 0.2rem;
  text-decoration: none;
  color: #f2f2f2;
}

/* -----------------
  News Page
---------------------*/
.news-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-list {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  overflow: scroll;
  width: 100%;
}

.news-article {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem;
}

.news-date {
  color: #A0A0A0;
  font-size: 0.75rem;
  margin-top: 2rem;
}

.news-background {
  height: 100%;
  position: sticky;
}

@media screen and (max-width: 900px) {
  .news-background {
    display: none;
  }
}

.news-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 5rem;
}

.news-header {
  background-color: #f2f2f2;
  position: fixed;
  width: 100%;
  margin-top: 0;
}

/* -----------------
    Datenschutz Page
  ---------------------*/
.datenschutz {
  background-color: #f2f2f2;
  padding: 1rem;
  margin-top: 5rem;
}/*# sourceMappingURL=Main.css.map */