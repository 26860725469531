@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');


  /* -----------------
    General variables
  ---------------------*/

  $light-color: #f2f2f2;
  $light-color-darker: #ededed;
  $dark-color: #303030;
  $middle-grey: #C0C0C0;
  $darker-middle-grey: #A0A0A0;
  $accent-yellow: #eeba29;
  $darker-yellow: #bd8723;
  $accent-red: #861e1e;

  $main-width: 100%;

  $font-size-title: 2rem;
  $font-size-text: 0.7rem;

  /* -----------------
    General
  ---------------------*/

body{
  background-color: $dark-color;
  font-family: 'Poppins', sans-serif;;
  font-size: 1.2rem;
  color: $dark-color;
}

.center-div{
  margin-right: auto;
  margin-left: auto;
}


.body-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-width{
  width: 300px;
}

.section-title{
  
  margin: 0;

  color: $light-color;
  font-size: 1.7rem;  
}

.section-title-big{
  margin: 0;

  color: $light-color;
  font-size: 3rem;  
}

.background-text{
  font-size: 1rem;
}

.title-right{
  margin-left: auto;
  text-align: end;
  padding-right: 1rem;
}

.title-left{
  margin-right: auto;
  text-align: left;
  padding-left: 1rem;
}

.call-to-action{
  height: 3rem;
  width: 10rem;

  background-color: $accent-yellow;
  border: none;

  font-weight: bold;
  font-size: 1.2rem;
}

.option-button{
  height: 3rem;
  width: 10rem;

  background-color: $middle-grey;
  border: none;

  font-weight: bold;
  font-size: 1.2rem;
}

.call-to-action:hover{
  background-color: $darker-yellow;
  cursor: pointer;
}

.option-button:hover{
  background-color: $darker-middle-grey;
  cursor: pointer;
}

.card-shadow{
  -webkit-box-shadow: 0px 6px 13px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 6px 13px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 6px 13px 0px rgba(0,0,0,0.1);
}

.card-title-shadow{
  -webkit-text-shadow: 0px 6px 13px rgba(0,0,0,0.1);
  -moz-text-shadow: 0px 6px 13px rgba(0,0,0,0.1);
  text-shadow: 0px 6px 13px rgba(0,0,0,0.1);
}

.mobile-heading{
  @media screen and (min-width: 901px) {
    display: none;
  }
}

.desktop-heading{
  @media screen and (max-width: 900px) {
    display: none;
  }
}

  /* -----------------
    Header variables
  ---------------------*/

$header-height: 5rem;

 /* -----------------
    Header
  ---------------------*/

.header-wrapper{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    justify-self: stretch;
    
    height: $header-height;
    width: 0.9 * $main-width;
    min-width: 250px;

    margin-top: 1rem;
    z-index: 1;

    @media screen and (max-width: 900px) {
      position: fixed;
      background-color: $light-color;
      width: 100%;
      margin-top: 0;

      -webkit-box-shadow: 0px 6px 13px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 6px 13px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 6px 13px 0px rgba(0,0,0,0.1);
    }

    @media  screen and (max-width: 350px) {
      justify-content: flex-end;
    }
  
  }
  .header-icon{
    height: $header-height;
    z-index: 100;
    @media screen and (max-width: 450px) {
      display: none;
    }
  }
  .header-title{
    height: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 2rem;
    @media screen and (max-width: 450px) {
      margin-right: 0.5rem;;
    }

    @media  screen and (max-width: 350px) {
      margin-left: 1rem;
      height: 1.25rem;
    }
  }
  .header-box {
    background-color: $light-color;
    height: 1rem;
    width: 95%;
    margin-left: -1rem;
    z-index: -100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    @media screen and (max-width: 900px) {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
  }
  .main-menu-item{
    text-decoration: none;
    margin-left: 1rem;
  }
  .main-menu{
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .main-menu-mobile{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position:fixed;

    margin-top: 3.5rem;
    margin-right: -1rem;

    padding: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;

    width: 40%;

    background-color: $light-color-darker;
  }

  .mobile-menu-wrapper{
    display: none;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
    }
  }

  .mobile-menu-item{
    text-decoration: none;
    font-size: 1.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .mobile-menu-seperator{
    width: 100%;
  }

  /* -----------------
    Cookie Consent
  ---------------------*/

.cookie-banner{
    
    position: fixed;
    bottom: 0;
    right: 0;

    padding: 2rem;
    padding-top: 0rem;
    padding-left: 4rem;

    background-color: $light-color;

    z-index: 1;

    @media screen and (min-width: 901px) {
      width: 0.4 * $main-width;
    }
  }

  .second-button{
    margin-left: 1rem;
  }

   /* -----------------
    Background Image
  ---------------------*/

.back-image{
  width: $main-width;
  min-width: none;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 900px) {
  .first-background{
    	margin-top: $header-height;
  }

  
}

@media screen and (min-width: 900px) {
  .back-image{
    min-width: 1400px;
  }
}



  /* -----------------
    News-Card
  ---------------------*/

  .news-card-wrapper{
    width: 0.7 * $main-width;

    @media screen and (max-width: 900px) {
      width: 100%;
      margin-top: -3rem;
    }
  }

  .news-card{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    overflow: hidden;    
    padding: 1rem;
    padding-right: 2rem;

    background-color: $light-color;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      padding-right: 1rem;
    }
  }

  .news-image{
    width: 20rem;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .news-text-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: auto;

    justify-self: stretch;

    
    margin-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media screen and (max-width: 900px) {
      
    }

  }

  .news-text{
    max-height: 3.5rem;
    overflow: hidden;

    @media screen and (max-width: 900px) {
      max-height: none;
      overflow: auto;
    }
  }


  

    /* -----------------
    Address
  ---------------------*/

  .address-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    
    padding-top: 4rem;

    width: $main-width;
    height: 20rem;
    background-color: $light-color-darker;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      padding-top: 2rem;
      height: 34rem;
    }
  }

  .column-middle{
    @media screen and (max-width: 900px) {
      order: 1;
    }
  }

  .address-column{
    text-align: center;
    @media screen and (max-width: 900px) {
      margin-right: 3rem;
      margin-left: 3rem;
    }
   
  }

  /* -----------------
    Leasing Card
  ---------------------*/

  .leasing-card-wrapper{
    width:  $main-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    margin-left: 3rem;

    @media screen and (max-width: 900px) {
      width: 100%;
      margin-left: 0;
      margin-top: -5rem;
    }
  }

  .leasing-card{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    overflow: hidden;  
    
    margin-right: auto;
    padding: 1rem;
    padding-right: 2rem;

    width: 30rem;
    background-color: $light-color;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      width: 100%;
      padding-right: 1rem;
      box-sizing: border-box;
    }
  }

  .leasing-text-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: auto;


    margin-left: 2rem;
    padding-bottom: 1rem;

    width: 50%;

    @media screen and (max-width: 900px) {
      
    }

  }

  .leasing-text{
    overflow: hidden;

    @media screen and (max-width: 900px) {
      max-height: none;
      overflow: auto;
    }
  }

  .leasing-list{
    width: 50%;
  }

  /* -----------------
    Service Finanzierung
  ---------------------*/

  .service-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 2rem;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .service-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    
    padding-top: 1rem;

    width: $main-width;
    height: 22rem;
    background-color: $light-color-darker;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 2rem;
      height: 31rem;
    }

    @media screen and (max-width: 600px) {
      height: 40rem;
    }
  }

  .service-column{
    width: 33rem;
    margin-left: 2rem;

    @media screen and (max-width: 900px) {
      margin-right: 2rem;
      width: unset;
    }
  }

  /* -----------------
    Ebike Card
  ---------------------*/

  .ebike-card-wrapper{
    width:  $main-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    margin-right: 3rem;

    @media screen and (max-width: 900px) {
      width: 100%;
      margin-right: 0;
      margin-top: -5rem;
    }
  }

  .ebike-card{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    overflow: hidden;  
    
    margin-left: auto;
    margin-right: 3rem;
    padding: 1rem;

    width: 30rem;
    background-color: $light-color;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      width: 100%;
      margin-right: 0;
      box-sizing: border-box;
    }
  }


  /* -----------------
    Service Werkstatt
  ---------------------*/

  .service-wrapper-werkstatt{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    padding-top: 1rem;

    width: $main-width;
    height: 22rem;
    background-color: $light-color-darker;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 2rem;
      height: 45rem;
    }
  }

  /* -----------------
    Products
  ---------------------*/

  .product-wrapper{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;

    
    padding-top: 0rem;

    width: $main-width;
    height: 50rem;
    background-color: $light-color-darker;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 2rem;
      height: 90rem;
    }
    
    @media screen and (max-width: 450px) {
      height: 67rem;
    }
  }

  .product-gallery{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:center;
    align-items: flex-start;

  }

  .product-column{
    width: 15rem;
    margin-left: 2rem;
    margin-right: 2rem;
    @media screen and (((min-width: 901px) and  (max-width: 1250px)) or (max-width: 640px)){
      width: 11rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @media screen and (max-width: 450px) {
      width: 7rem;
    }
  }

  .product-row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 3rem;
    margin-bottom: 4rem;

    height: 8rem;

    @media screen and (max-width: 450px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .product-logo{
    max-width:100%;
    max-height: 100%;
  }

  /* -----------------
    Contact Form
  ---------------------*/

  .contact-card-wrapper{
    width:  $main-width;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    

    @media screen and (max-width: 900px) {
      width: 100%;
      box-sizing: border-box;
    }

    @media screen and  (max-width: 900px) and  (min-width: 600px){
      flex-direction: row;
      align-items: center;
      background-color: $light-color;
    }

    @media screen and  (max-width: 600px){
      margin-top: -5rem;
    }
  }

  .contact-error{
    font-size: 0.75rem;
    color: $accent-red;
    margin-left: 1rem;
  }

  .contact-title{
    margin: 0;

    color: $light-color;
    font-size: 3rem;  

    @media screen and  (max-width: 900px) and (min-width: 600px){
      color: $dark-color;
    }
  }

  .contact-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    overflow: hidden;  
    
    margin-right: auto;
    padding: 1rem 3rem 1rem 3rem;

    width: 22rem;
    background-color: $light-color;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      width: 100%;
      padding: 1rem;

      box-sizing: border-box;

      -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
  }

  .contact-input{
    margin-top: 0rem;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
  }

  .contact-message{    
    height: 8rem;
    resize: none;
  }

  .submit-area{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .contact-submit{
    margin-top: 0.5rem;
    height:  2.5rem;
  }

  .contact-success{
    margin-left: 1rem;
    font-size: 1rem;
  }

  /* -----------------
    Contact Maps
  ---------------------*/

  .contact-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: $main-width;
    height: 40rem;
    background-color: $light-color-darker;

    @media screen and (max-width: 900px) {
      height: 70rem;
    }
    @media screen and (max-width: 500px) {
      height: 50rem;
    }
  }

  .anfahrt-title{
    margin-top: 1rem;
    margin-left: 1rem;
    margin-bottom: 0rem;
  }

  .maps-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    width: 100%;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  
  .maps-iframe{
    display: block;
    width: 45%;
    height: 30rem;
    margin-top: 1rem;
    
    @media screen and (max-width: 900px) {
      width: 95%;
    }    
    
    @media screen and (max-width: 500px) {
      height: 20rem;
    }

  }

  /* -----------------
    Footer
  ---------------------*/

.footer-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 2rem;

  font-size: $font-size-text;

  color: $light-color;
  background-color: $dark-color;  
}

.footer-logo-title{
  width: 15rem;
}
.footer-logo-icon{
  width: 2rem;
}

.footer-nav{
  display: flex;
  flex-direction: column;


  margin-top: 1rem;
}

.footer-nav-item{
  margin-top: 0.2rem;
  
  text-decoration: none;
  color: $light-color;


}

  /* -----------------
    News Page
  ---------------------*/

.news-page{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.news-list{
  display: flex;
  flex-direction: column;
  background-color: $light-color;
  overflow: scroll;
  width: 100%;
}

.news-article{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 2rem;
}

.news-date{
  color: $darker-middle-grey;
  font-size: 0.75rem;
  margin-top: 2rem;
}

.news-background{
  height: 100%;
  position: sticky;
}

.news-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  width: 100%;
  margin-top: $header-height;
}

.news-header{
  background-color: $light-color;
  position: fixed;
  width: 100%;

  margin-top: 0;
}

/* -----------------
    Datenschutz Page
  ---------------------*/

.datenschutz{
  background-color: $light-color;
  padding: 1rem;
  margin-top: $header-height;
}